import React, { useState } from 'react'
import Footer from './Footer'
import emailjs from 'emailjs-com';
import CInputText from './controls/CInputText';
import CSTextArea from './controls/CSTextArea';

const SERVICE_ID = "service_zqyv4xl";
const TEMPLATE_ID = "template_0fnomnk";
const PUBLIC_KEY = "2IW2VzxvpLkVBXqZo";

export default function Contact2() {

  const [mName, setName] = useState('');
  const [mEmail, setMail] = useState('');
  const [mMessage, setMessage] = useState('');
  const handleOnSubmit = (e) => {
      e.preventDefault();
      emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY)
          .then((result) => {
              console.log(result.text);
              alert('Message Sent Successfully')
          }, (error) => {
              console.log(error.text);
              alert('Something went wrong!')
          });
      e.target.reset()
  };
  return (
    <div className='col-12 p-0 m-0 h-screen overflow-scroll'>
    <div className="col-12 text-center">
        <div className="text-900 text-3xl font-bold">Contact Us</div>
        <div className="csunderline"></div>
      </div>
      <div className="col-12 surface-card shadow-3 mx-3 py-1 flex flex-wrap justify-content-between md:py-8 my-8">
      <div className="col-12 lg:col-6 px-8 border-round ">
      <form class="formContainer" onSubmit={handleOnSubmit}>
                <CInputText
                    mvalue={mName}
                    pOnChange={setName}
                    placeholder={' '}
                    pName={'from_name'}
                    ptype={'text'}
                    pLabel={'Name'}
                    pLabelId='from_name'
                    padding='0.5rem 0.75rem'
                    marginBottom='10px'
                    pRequired={true}
                    pClassName = {'mb-2'}
                />

                <CInputText
                    mvalue={mEmail}
                    pOnChange={setMail}
                    placeholder={' '}
                    pName={'from_email'}
                    ptype={'email'}
                    pLabel={'Email'}
                    pLabelId='from_email'
                    padding='0.5rem 0.75rem'
                    marginBottom='10px'
                    pRequired={true}
                    pClassName = {'mb-2'}
                />

                <CSTextArea
                    mvalue={mMessage}
                    pOnChange={setMessage}
                    placeholder={''}
                    pName={'message'}
                    pLabel={'Message'}
                    pLabelId='Message'
                    padding='0.5rem 0.75rem'
                    marginBottom='10px'
                    height='100%' />
                <button
                    type='submit'
                    label="Cancel"
                    style={{backgroundColor:'#108040', color:'white', marginTop:'2%', border: '0px solid transparent', padding: '8px', borderRadius: '4px', width:'30%', float:'right', right:'3%'}}
                    onClick={() => { if (!visible) return; setVisible(false); }}>
                    Submit
                </button>
            </form>
        </div>
        <div className="col-12 lg:col-6">
        <div className="block font-bold border-round"> <iframe className="w-9 h-14rem" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=SunMars%20Residency,%20nizampet+(Chinmmai%20Systems)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/population/">Population calculator map</a></iframe></div>
          <div className=" block font-bold p-1 border-round ">
            <a href='www.chinmmaisystems.com' target="_blank">
              <h3 className="flex align-items-center m-1"><i className="pi pi-whatsapp mr-3"></i><span>+91-9032849685</span></h3></a>
          </div>
          <div className="block font-bold p-1 border-round ">
            <h3 className="flex align-items-center m-1"><i className="pi pi-inbox mr-3"></i><span>ask@chinmmaisys.com</span></h3>
          </div>
          <div className="block font-bold p-1 border-round ">
            <h3 className="flex align-items-center m-1"><i className="pi pi-directions mr-3"></i><span>Hyderabad, Telangana, India.</span></h3>
          </div>
        </div>
        </div>
      <Footer/>
</div>
  )
}
