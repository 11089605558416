import { useContext } from 'react';
import React from 'react';
import { Menubar } from 'primereact/menubar';
import { InputText } from 'primereact/inputtext';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { DataContext } from './context/DataContext';
import { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Link } from 'react-router-dom';
import { Data } from '@react-google-maps/api';
import { redirect } from 'react-router-dom'

function Topbar() {
    const ProductData = useContext(DataContext);
    const { ProductName, PhoneNumber } = ProductData;
    const [visible, setVisible] = useState(false);
    const [mData, setData] = useState(
        {
            name: '',
            email: '',
            phone: '',
            website: '',
            message: '',

        }
    );
    const { name, email, phone, website, message } = mData
    const changeHandler = e => {
        setData({ ...mData, [e.target.name]: [e.target.name] })
    }
    const submitHandler = e => {
        mData()
    }


    const topbarbuttons = (
        <div className='flex p-2'>
            {/* <Button label={PhoneNumber} type="button" className=' transparent ' /> */}
            {/* <Button label="Book Free Demo" icon="" className='w-8rem bg-teal-900 text-white' onClick={() => setVisible(true)} /> */}
            <Dialog header="Book Free Demo" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>

                <div className="col-12">
                    <form id="contact" action="" method="post">
                        <h3>Book Free Demo</h3>
                        <h4>Contact us for custom quote</h4>
                        <fieldset>
                            <InputText placeholder="Your name" type="text" tabIndex="1" value={name} onChange={changeHandler} required autofocus />
                        </fieldset>
                        <fieldset>
                            <InputText placeholder="Your Email Address" type="email" tabIndex="2" value={email} onChange={changeHandler} required />
                        </fieldset>
                        <fieldset>
                            <InputText placeholder="Your Phone Number (optional)" type="tel" tabIndex="3" value={phone} onChange={changeHandler} required />
                        </fieldset>
                        <fieldset>
                            <InputText placeholder="Your Web Site (optional)" type="url" tabIndex="4" value={website} onChange={changeHandler} required />
                        </fieldset>
                        <fieldset>
                            <textarea placeholder="Type your message here...." tabIndex="5" value={message} onChange={changeHandler} required></textarea>
                        </fieldset>
                        <fieldset>
                            <button name="submit" type="submit" onSubmit={submitHandler}>Submit</button>
                        </fieldset>
                    </form>
                </div>
            </Dialog>

        </div>
    )
    return (
        <div >
            <div className='col-12 flex align-content-center flex-wrap justify-content-between bg-white'>
                <Link href="/" className="align-items-center">
                    <img src='./website/Chinmmaisystemss.png' alt="CS Logo" height="50" className="mr-0 lg:mr-2" />
                    {/* <span className="text-900 font-medium text-2xl line-height-3 mr-8">Chinmmai Systemss</span> */}
                </Link>
                {/* <div className="flex flex-wrap justify-content-start ">
                    <img src="./website/Chinmmaisystemss.png" className="mx-3 w-full" alt="logo" height="50" />
                </div> */}
                {/* <div className="layout-topbar-icons">
                    <a href='https://mks.chinmmaisystems.com/' target="_blank" type="button" icon="pi pi-user" className='w-5rem h-3rem bg-primary text-white border-round-lg border-3 border-primary-light text-center hidden lg:block' style={{ padding: '12px 14px 14px 14px' }}>Login</a>
                </div> */}
            </div>
        </div>
    )
}

export default Topbar