import React from 'react'
import Footer from './Footer'

function Aboutus() {
    return (
        <div className='h-screen overflow-scroll'>
            <div className="flex flex-wrap justify-content-center align-content-center lg:py-10 ">
                <div className="col-12 md:col-3 lg:col-3 flex flex-wrap justify-content-center">
                    <img src='./website/aboutbg1.png' className='align-items-center' height={300} />
                </div>
                <div className="col-8 md:col-6 lg:col-9 flex flex-wrap justify-content-center" style={{ borderRadius: '30px' }}>
                    <p className="text-900 w-full text-justify sm:line-height-2 md:line-height-3 text-sm mb-1 " style={{ maxWidth: '960px' }}>
                        "Chinmmai Systemss" is a leading technology firm specializing in innovative solutions tailored for various industries, leveraging our cutting-edge proprietary no-code platform. Covering the universe of Manufacturing, Trading and Service industries, we have successfully developed and deployed user-friendly applications that address industry-specific challenges. Our no-code approach empowers clients to rapidly create, customize, and deploy software solutions without the need for traditional coding skills. This unique capability has positioned "Chinmmai Systemss" as a trusted partner for organizations seeking efficient and tailored technology solutions. </p>
                    <p className="text-900 text-justify sm:line-height-2 md:line-height-4 text-sm mb-1" style={{ maxWidth: '960px', }}>
                        "Chinmmai Systemss" stands at the forefront of technological innovation, specializing in bespoke solutions for various industries through our cutting-edge proprietary no-code platform. With an unwavering commitment to agility and cost-effectiveness, we empower our clients to accelerate their digital transformation journeys.
                    </p>
                    <p className="text-900 text-justify sm:line-height-2 md:line-height-4 text-sm mb-1" style={{ maxWidth: '960px', }}>
                        Our no-code platform sets us apart, enabling the swift development and deployment of new or enhanced industry solutions. Unlike traditional development approaches, our streamlined process allows us to deliver high-quality software in record time, giving our clients a competitive edge in the fast-paced business landscape. The efficiency of our platform not only reduces time-to-market but also translates into highly competitive pricing, making advanced technology solutions accessible to a broad spectrum of businesses.
                    </p>
                    <p className="text-900 text-justify sm:line-height-2 md:line-height-4 text-sm mb-1" style={{ maxWidth: '960px', }}>
                        At "Chinmmai Systemss", we thrive on the intersection of innovation and practicality, creating tailored solutions that align seamlessly with the unique challenges of any business. As your trusted technology partner, we are dedicated to propelling your business forward by unlocking your full potential, ensuring you stay ahead of the curve in the ever-evolving digital landscape.
                    </p>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Aboutus