import React, { useContext } from 'react'
import { DataContext } from './context/DataContext';

function Footer() {

   const ProductData = useContext(DataContext);
   const { ProductName, PhoneNumber, Email, Menu1, Menu2, Menu3, Menu4, Menu5, FooterMenu5 } = ProductData;
   return (
      <div className="h-12rem align-items-center bg-primary-light py-2 align-items-center text-white absoulte left-0 right-0 lg:px-8 bottom-0  ">
         <div className="flex flex-column sm:flex-row align-items-center justify-content-center lg:py-1  ">
            <div className="flex mt-1 sm:mt-0 align-items-center lg:pr-8"><img src="./website/cs-small.png" alt="Image" height="40" /><span className='font-bold text-xl'>CHINMMAI SYSTEMSS</span></div>

            <div className="flex flex-nowrap font-medium pl-8">
               <p>Copyright &copy; 2024, Chinmmai Systemss Private Limited. All Rights Reserved.</p>
            </div>
         </div>
      </div>
   )
}

export default Footer
