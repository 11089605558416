import { Outlet, Link } from "react-router-dom";
import Topbar from "./Topbar";

const Layout = () => {
  return (
    <>
      <Topbar />
      <nav className=" bg-primary border-top-3 border-bottom-3 border-primary-light navbar">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About Us</Link>
          </li>
          <li>
            <Link to="/products">Solutions</Link>
          </li>
          <li>
            <Link to="/contact">Contact us</Link>
          </li>
        </ul>
      </nav>

      <Outlet />
    </>
  )
};

export default Layout;