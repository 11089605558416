
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DataView from './DataView';
import About from './Aboutus';
import Banner from './NewBanner';
import CSDynamo from './CSDynamo';
import Layout from './Layout';
import './assets/styles/website.css';
import 'primereact/resources/primereact.css';
import Contact from './Contact';
import CSSlider from './CSSlider';
import NewSlider from './NewSlider';
import Contact2 from './Contact2';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<NewSlider />} />
            <Route path="about" element={<About/>} />
            <Route path="products" element={<DataView />} />
            <Route path="services" element={<CSSlider />} />
            <Route path="contact" element={<Contact2 />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>

  );
}

export default App;
