import React, { useState, useEffect } from 'react';
import { Carousel } from 'primereact/carousel';
import { PhotoService, ProductService } from './PhotoService';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import Footer from './Footer';

export default function NewSlider() {
  const [images, setImages] = useState(null);

  useEffect(() => {
    PhotoService.getPhotos().then((data) => setImages(data.slice(0, 3)));
  }, []);
  const responsiveOptions = [
    {
      breakpoint: '1199px',
      numVisible: 1,
      numScroll: 1
    },
    {
      breakpoint: '991px',
      numVisible: 2,
      numScroll: 1
    },
    {
      breakpoint: '767px',
      numVisible: 1,
      numScroll: 1
    }
  ];
  const itemTemplate = (item) => {
    return <img src={item.image} alt={item.description} style={{ width: '100%', display: 'block' }} />;
  };

  return (
    <div className='h-screen overflow-scroll mb-6'>
      <div className=' hidden lg:block'>
        <Carousel value={images} numVisible={1} numScroll={1} responsiveOptions={responsiveOptions} className="custom-carousel w-full border-bottom-3 border-primary-light  " circular
          autoplayInterval={3000} itemTemplate={itemTemplate} showNavigators={false} showIndicators={false} />
      </div>
      <div className="py-6">
        <h2 className="text-900 text-center font-bold text-2xl">CS Dynamo</h2>
        <div className='csunderline'></div>
        <div className='grid px-8'>
          <div className='col-12 md:col-6'>
            <div className='text-sm dynamo text-600' >
            <div className='text-primary font-semibold text-base'>CS Dynamo: Enterprise Solution Development</div>
              <ul className='px-5 py-2'>
                <li>Cutting-edge No Code Platform</li>
                <li>Revolutionize workflow with agility</li>
                <li>Rapid application development with intuitive drag-and-drop interface</li>
                <li>No coding expertise required</li>
                </ul>
                <div className='text-primary font-semibold text-base'>Key Features</div>
                <ul className='px-5 py-2'>
                <li>Seamless integration of diverse functionalities</li>
                <li>Streamline processes and enhance productivity</li>
                <li>Speed and efficiency from concept to deployment</li>
                <li>Scalable solutions evolving with your business</li>
                <li>Collaborative Advantages</li>
                <li>Real-time collaboration for multiple contributors</li>
                <li>Break down silos and foster a culture of innovation</li>
                <li>Keep enterprise solutions up-to-date and agile</li>
                <li>Digital Transformation Catalyst</li>
                <li>Empowerment for CRM systems, project management tools, and data analytics dashboards</li>
                <li>Turn ideas into Realty swiftly and seamlessly</li>
                </ul>
                <div className='text-primary font-semibold text-base '>CS Dynamo Impact</div>
                <ul className='px-5 py-2'>
                <li>Join forward-thinking enterprises embracing CS Dynamo</li>
                <li>Transform business processes and reduce development cycles</li>
                <li>Unlock unparalleled agility in enterprise solution creation</li>
              </ul>
            </div>
          </div>
          <div className='col-12 md:col-6 sm:col-12 flex align-content-center flex-wrap justify-content-end  '>
            <img className='w-full lg:w-9' src='./website/controls.png' />
          </div>
        </div>
      </div>

      <div className="surface-ground py-5 flex flex-wrap ">
        <div className='col-12 lg:col-5 md:col-4 text-5xl text-center'><span className=''>Industry Solutions</span>
          <span className=''><img className='pt-3' src='./website/IndustrySolutionsBanner.png' /></span>
        </div>
        <div className="col-12 lg:col-7 md:col-8 flex flex-wrap p-3 gap-3  ">
          <div className="col-12 lg:col-5 shadow-2 surface-card border-round text-center mb-6 px-3 ">
            <div className="mb-4 ">
              <img src="./website/Industry1.png" width="90" height="80" className="-mt-6 " alt="Industry" /><span className="block text-900 mb-1 text-xl font-bold">Manufacturing</span>
              <p className="text-600 m-0 font-medium">"Transform manufacturing with our cutting-edge software: real-time monitoring, efficiency, precision, scalability, and seamless integration for a transformative production experience with data-driven decision support."</p>
            </div>
          </div>

          <div className="col-12 lg:col-5 shadow-2 surface-card border-round text-center mb-6 px-3">
            <div className="mb-4">
              <img src="./website/Education.png" width="80" height="80" className="-mt-6" alt="Education" /><span className="block text-900 mb-1 text-xl font-bold">Education</span>
              <p className="text-600 m-0 font-medium">"Transform education management with our integrated software solution. From enrollment to graduation, streamline processes, enhance communication, and empower educational institutions for a seamless and efficient experience."</p>
            </div>
          </div>

          <div className=" col-12 lg:col-5 shadow-2 surface-card border-round text-center mb-6 px-3   ">
            <div className="mb-4">
              <img src="./website/Retail1.png" width="80" height="80" className="-mt-6" alt="Retail" /><span className="block text-900 mb-1 text-xl font-bold">Retail</span>
              <p className="text-600 m-0 font-medium">"Elevate retail management with our integrated software. From inventory to sales, enhance efficiency, optimize customer engagement, and boost profitability with our seamless and intuitive solution."</p>
            </div>
          </div>

          <div className="col-12 lg:col-5 shadow-2 surface-card border-round text-center mb-6 px-3  ">
            <div className="mb-4">
              <img src="./website/RealEstate.png" width="80" height="80" className="-mt-6" alt="RealEstate" /><span className="block text-900 mb-1 text-xl font-bold">Realty</span>
              <p className="text-600 m-0 font-medium">"Optimize your real estate business with our integrated software, promoting seamless collaboration across the project lifecycle. Enhance efficiency, streamline communication, and ensure success from planningto completion."</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

{/* <p className="text-900 text-justify sm:line-height-2 md:line-height-6 text-sm mt-2 " style={{ maxWidth: '790px' }}>
                Discover the next frontier in enterprise solution development with "CS Dynamo", our cutting-edge No Code Platform. Say goodbye to traditional coding hassles and embrace a new era of agility. "CS Dynamo" empowers you to create robust, tailored applications with an intuitive drag-and-drop interface.</p>
              <p className="text-900 text-justify sm:line-height-2 md:line-height-6 text-sm mt-2 " style={{ maxWidth: '790px' }}>
                Revolutionize your workflow by rapidly developing applications suited to your unique business needs. With "CS Dynamo", innovation is at your fingertips, no coding expertise required. Seamlessly integrate diverse functionalities, streamline processes, and enhance productivity across your organization.</p>
              <p className="text-900 text-justify sm:line-height-2 md:line-height-6 text-sm mt-2 " style={{ maxWidth: '790px' }}>
                "CS Dynamo" is designed for speed and efficiency, allowing you to go from concept to deployment in record time. Leverage our platform to build scalable solutions that evolve with your business. Collaborate effortlessly with cross-functional teams, breaking down silos and fostering a culture of innovation.</p>
              <p className="text-900 text-justify sm:line-height-2 md:line-height-6 text-sm mt-2 " style={{ maxWidth: '790px' }}>
                Stay ahead of the curve with 'CS Dynamo's real-time collaboration features, enabling multiple contributors to work concurrently on projects. Our platform ensures that your enterprise solutions are always up-to-date, agile, and ready to adapt to changing business landscapes.</p>
              <p className="text-900 text-justify sm:line-height-2 md:line-height-6 text-sm mt-2 " style={{ maxWidth: '790px' }}>
                "CS Dynamo" isn't just a No Code Platform; it's a catalyst for digital transformation. Whether you're developing CRM systems, project management tools, or data analytics dashboards, "CS Dynamo" empowers you to turn ideas into Realty swiftly and seamlessly.</p>
              <p className="text-900 text-justify sm:line-height-2 md:line-height-6 text-sm mt-2 " style={{ maxWidth: '790px' }}>
                Join the ranks of forward-thinking enterprises that have embraced the power of "CS Dynamo". Transform your business processes, reduce development cycles, and unlock unparalleled agility. Experience the future of enterprise solution creation-welcome to "CS Dynamo."</p> */}